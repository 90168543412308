<template>
    <div class="sidebar-view">
        <LeftSidebar @loadApplication="loadFromSidebar" />
    </div>
    <div class="content-view">
        <keep-alive>
            <component
                :is="currentComponent"
                :status="status"
                :vacancies="vacancies"
            ></component>
        </keep-alive>
    </div>
</template>

<script>

import LeftSidebar from "@/components/applications/LeftSidebar";
import { defineAsyncComponent } from "vue";
import { candidateService } from "@/services/candidateService";

export default {
    name: "CandidateApplicationsPanel",
    components: {
        LeftSidebar,
        vacancies: defineAsyncComponent(() =>
            import("@/views/applications/candidate/Stage_1")
        ),
        application: defineAsyncComponent(() =>
            import("@/views/applications/candidate/Stage_2")
        ),
    },
    data() {
        return {
            currentComponent: 'vacancies',
            status: 'pending',

            application: {}
        }
    },
    methods: {
        getVacancies(searchQuery = '') {
            applicationService.getVacanciesByStatus(this.status, {
                q: searchQuery
            }).then(response => {
                this.vacancies = response.data.data;
            })
        },
        loadFromSidebar(data) {
            const { status, query } = data;
            this.status = status;
            this.currentComponent = 'vacancies';
            this.getVacancies(query);
        },
    }
}
</script>